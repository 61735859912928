import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { patientChanged } from 'services/state/actions';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string };

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Patient();
    }

    bind() {
        return this.controller.validate();
    }

    get canCreate() {
        this.cancelled = !this.isPharmacyAllowedTo.isAllowed;
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('editPatient');

            if (!this.isPharmacyAllowedTo.isAllowed) {
                return;
            }

            this.entity = await (this.repository as ImpiloRepository).getPatient(params.guid, false);
        } else {
            this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('createPatient');
        }
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('patients');
    }

    async apply() {
        if (this.canApply) {
            await super.apply();
        }
        this.router.navigateToRoute('clinical');
    }

    // abstract get displayName();
    @computedFrom('entity.firstName', 'entity.surname')
    get displayName() {
        const patient = this.entity as ImpiloDb.Tables.Patient;
        const displayName = `${patient.firstName} ${patient.surname}`;
        queueMicrotask(() => patientChanged(displayName, patient));
        return displayName;
    }
}

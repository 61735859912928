import 'jsondiffpatch/dist/formatters-styles/html.css';
import 'jsondiffpatch/dist/formatters-styles/annotated.css';

export class JsonDetail {
    model;
    jsonDiff;

    bind(bindingContext, overrideContext) {
        if (overrideContext && overrideContext.parentOverrideContext && overrideContext.parentOverrideContext.bindingContext && overrideContext.parentOverrideContext.bindingContext.model) {
            return this.bindReferences(overrideContext.parentOverrideContext.bindingContext);
        }
    }

    async bindReferences(data) {
        if (data) {
            this.model = data.model;
            this.jsonDiff = this.model.results;
        }
    }
}
import { autoinject, observable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { connectTo } from 'aurelia-store';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'currentPatient')),
    target: 'current'
})
@autoinject()
export class Index {
    constructor(private readonly repository: ImpiloRepository, private router: Router) {
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'main' },
            { route: 'main', name: 'main', moduleId: PLATFORM.moduleName('./main/index'), title: 'Main', nav: true },
            { route: 'contact', name: 'contact', moduleId: PLATFORM.moduleName('./contact/index'), title: 'Contact', nav: true },
            { route: 'clinical', name: 'clinical', moduleId: PLATFORM.moduleName('./clinical/index'), title: 'Clinical', nav: true },
            { route: 'medications', name: 'medications', moduleId: PLATFORM.moduleName('./medications/index'), title: 'Medication', nav: true },
            { route: 'history', name: 'history', moduleId: PLATFORM.moduleName('./history/index'), title: 'History', nav: true },
        ]);
        this.router = router;
    }
}

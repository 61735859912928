import { autoinject, bindable } from 'aurelia-framework';
import { ImpiloRepository } from 'services/repositories';

@autoinject()
export class Notices {

    @bindable notices = [];

    constructor(private readonly repository: ImpiloRepository) {
    }

    async bind(params, routeConfig, navigationInstruction) {

        const response = await this.repository.getNotices();
        this.notices = response.results.sort((a, b) => a.created < b.created ? 1 : -1);
    }
}


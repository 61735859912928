import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, observable, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExtensionName, Formatters } from 'aurelia-slickgrid';
import { connectTo, Store } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { IState } from 'services/state/state';
import { isGuid, sleep } from '@dts/scriptlib';
import { diff, formatters, dateReviver } from "jsondiffpatch";

@autoinject()
export class Index extends GenericEntitySearch {

    guid;

    @observable private selectedEntity = null;
    entities = [
        {
            id: 1,
            name: 'Patient'
        },
        {
            id: 2,
            name: 'Patient Medication'
        },
        // {
        //     id: 3,
        //     name: 'Medication Schedule'
        // }
    ];

    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string } = { isAllowed: true, problem: '', solution: '' };

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {
            if (isGuid(this.guid) && this.selectedEntity) {

                this.closeAllRowDetail();

                this.isWaiting = true;
                const response = await this.repository.getHistory(this.selectedEntity, this.guid, page, size);
                this.isWaiting = false;

                this.gridDataset = response.results.map((entity) => {
                    return entity;
                });

                return response;
            }
        });
    }

    selectedEntityChanged() {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    get canCreate() {
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    async activate(params) {
        if (isGuid(params.guid)) {
            this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('editPatient');
            this.guid = params.guid;
            // return this.pager.gotoFirst();
        } else {
            this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('createPatient');
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            { id: 'sysStartTime', name: 'Date Time', field: 'sysStartTime', sortable: false, formatter: Formatters.dateTimeIso },
            { id: 'type', name: 'Type', field: 'type', sortable: false },
            { id: 'user', name: 'User', field: 'user', sortable: false },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            // enableCellNavigation: true,
            // enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            },
            enableRowDetailView: true,
            rowDetailView: {

                process: async (item) => {
                    let results;
                    let left = {};
                    let  right = {};
                    let delta;
1
                    switch (this.selectedEntity) {
                        case 'Patient':
                            const patientHistory = await this.repository.getPatientHistoryForJsonDiff(item.guid, item.sysStartTime);
                            if (patientHistory.results[0].leftSide[0]) {
                                left = JSON.parse(JSON.stringify(patientHistory.results[0].leftSide[0]), dateReviver);
                            }
                            if (patientHistory.results[0].rightSide[0]) {
                                right = JSON.parse(JSON.stringify(patientHistory.results[0].rightSide[0]), dateReviver);
                            }

                            delta = diff(left, right);

                            results = formatters.html.format(delta, null);

                            break;
                        case 'Patient Medication':
                            const patientMedicationHistory = await this.repository.getPatientMedicationHistoryForJsonDiff(item.guid, item.sysStartTime);

                            if (patientMedicationHistory.results[0].leftSide[0]) {
                                left = JSON.parse(JSON.stringify(patientMedicationHistory.results[0].leftSide[0]), dateReviver);
                            }
                            1
                            if (patientMedicationHistory.results[0].rightSide[0]) {
                                right = JSON.parse(JSON.stringify(patientMedicationHistory.results[0].rightSide[0]), dateReviver);
                            }

                            delta = diff(left, right);

                            results = formatters.html.format(delta, null);
                            break;
                    }

                    // tslint:disable-next-line: no-string-literal
                    item['results'] = results;

                    return Promise.resolve(item);
                },

                loadOnce: true,
                singleRowExpand: true,
                useRowClick: true,
                panelRows: 10,
                preloadView: PLATFORM.moduleName('./preload.html'),
                viewModel: PLATFORM.moduleName('./json-detail'),
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        // this.router.navigateToRoute('history', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        //
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        //
    }

    get rowDetailInstance(): any {
        if (this.aureliaGrid) {
            return this.aureliaGrid && this.aureliaGrid.extensionService.getSlickgridAddonInstance(ExtensionName.rowDetailView) || {};
        }
    }

    closeAllRowDetail() {
        if (this.rowDetailInstance) {
            this.rowDetailInstance.collapseAll();
        }
    }

    private readonly stringMatcher = (a, b) => {
        if (a && b) {
            return a === b;
        }

        return false;
    }
}
